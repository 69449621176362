import { Container, Typography, Paper, Select, FormControl, MenuItem, Button, InputLabel, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Box, Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

const ClassTeacherMapping = () => {
    const [ddlClass, setddlClass] = useState([]);
    const [ddlSection, setddlSection] = useState([]);
    const [selectedClassId, setSelectedClassId] = useState('');
    const [selectedSectionId, setSelectedSectionId] = useState('');
    const [classTeachers, setClassTeachers] = useState([]);
    const [teachersList, setTeacherList] = useState([]);
    const [selectedTeacherIds, setSelectedTeacherIds] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const fetchddlClass = async () => {
        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({}),
            });
            if (!response.ok) {
                throw new Error(`Error fetching class names: ${response.status}`);
            }
            const data = await response.json();
            setddlClass(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchddlSection = async (classId) => {
        try {
            const url = process.env.REACT_APP_BASE_URL;
            const apiUrl = `${url}/AssignmentCreate/ddlSection_clsId`;
            const token = sessionStorage.getItem('token');
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({ teacherId: 0, classId: classId }),
            });
            if (!response.ok) {
                throw new Error(`Error fetching sections: ${response.status}`);
            }
            const data = await response.json();
            setddlSection(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchClassTeachers = async (classId, sectionId) => {
        try {
            const url = process.env.REACT_APP_BASE_URL;
            const apiUrl = `${url}/ClassTeacherMapping/FetchClassTeacher`;
            const token = sessionStorage.getItem('token');
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({ classId: classId, sectionId: sectionId }),
            });
            if (!response.ok) {
                throw new Error(`Error fetching class teachers: ${response.status}`);
            }
            const data = await response.json();
            if (data.data === null && data.msg === "Record Not Found") {
                throw new Error("Record Not Found");
              }
            setClassTeachers(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTeachers = async () => {
        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${apiUrl}/AssignmentCreate/GetTeacher`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({}),
            });

           if (!response.ok) {
                throw new Error(`Error fetching class teachers: ${response.status}`);
            }
            const data = await response.json();
            if (data.data === null && data.msg === "Record Not Found") {
                throw new Error("Record Not Found");
              }
            setTeacherList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() =>{
        fetchddlClass();
        fetchTeachers();
    },[]);

    const handleClassChange = (event) => {
        const classId = event.target.value;
        setSelectedClassId(classId);
        fetchddlSection(classId);
    };

    useEffect(() => {
        if (selectedSectionId !== '') {
            fetchClassTeachers(selectedClassId, selectedSectionId);
        }
    }, [selectedSectionId]);

    const handleTeacherChange = (event, subjectId) => {
        const teacherId = event.target.value;
        setSelectedTeacherIds(prevState => ({
            ...prevState,
            [subjectId]: teacherId
        }));
    };

    const handleSave = async () => {
        // Check if at least one checkbox is selected
        const isChecked = Object.values(selectedTeacherIds).some(id => id !== undefined);
        if (!isChecked) {
            setIsSnackbarOpen(true);
            return;
        }

        try {
            const url = process.env.REACT_APP_BASE_URL;
            const apiUrl = `${url}/ClassTeacherMapping`;
            const token = sessionStorage.getItem('token');

            // Filter out the rows where the checkbox is not checked
            const selectedRows = classTeachers.filter(teacher => selectedTeacherIds[teacher.subjectId] !== undefined);

            // Prepare data for only the selected rows
            const requestData = selectedRows.map(teacher => ({
                classId: selectedClassId,
                sectionId: selectedSectionId,
                subjectId: teacher.subjectId,
                teacherId: selectedTeacherIds[teacher.subjectId] || 0 // Default to 0 if no teacher is selected
            }));

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify(requestData),
            });
            if (!response.ok) {
                throw new Error(`Error saving class teachers: ${response.status}`);
            }
            setSuccessMessage('Class teachers saved successfully');
        } catch (error) {
            console.error('API request error:', error);
            setErrorMessage('Failed to save class teachers');
        }
    };

    const handleCloseSuccessAlert = () => {
        setSuccessMessage('');
    };

    const handleCloseErrorAlert = () => {
        setErrorMessage('');
    };

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '50vh',
                marginTop: '30px'
            }}
        >
            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Class Teacher Mapping
                </Typography>
                <form style={{ width: '300px' }}>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel htmlFor="class">Class:</InputLabel>
                        <Select
                            id="class"
                            label="Class"
                            value={selectedClassId}
                            onChange={handleClassChange}
                        >
                            <MenuItem value="">Select Class</MenuItem>
                            {ddlClass.map((item) => (
                                <MenuItem key={item.classId} value={item.classId}>
                                    {item.className}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel htmlFor="section">Section:</InputLabel>
                        <Select
                            id="section"
                            label="Section"
                            value={selectedSectionId}
                            onChange={(e) => setSelectedSectionId(e.target.value)}
                        >
                            <MenuItem value="">Select Section</MenuItem>
                            {ddlSection.map((item) => (
                                <MenuItem key={item.sectionId} value={item.sectionId}>
                                    {item.sectionName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </form>
            </Paper>
            {classTeachers.length > 0 && (
                <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, width: '50vw' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Select</TableCell>
                                <TableCell>Serial Number</TableCell>
                                <TableCell>Subject Name</TableCell>
                                <TableCell>Teacher</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {classTeachers.map((teacher, index) => (
                                <TableRow key={teacher.subjectId}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedTeacherIds[teacher.subjectId] !== undefined}
                                            onChange={(e) => handleTeacherChange(e, teacher.subjectId)}
                                        />
                                    </TableCell>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{teacher.subjectName}</TableCell>
                                    <TableCell>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <InputLabel htmlFor={`teacher-${index}`}>Teacher</InputLabel>
                                            <Select
                                                id={`teacher-${index}`}
                                                defaultValue=""
                                                fullWidth
                                                label="Teacher"
                                                onChange={(e) => handleTeacherChange(e, teacher.subjectId)}
                                            >
                                                <MenuItem value="">Select Teacher</MenuItem>
                                                {teachersList.map((item) => (
                                                    <MenuItem key={item.employeeId} value={item.employeeId}>
                                                        {item.employeeName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <Button variant="contained" color="primary" size="large" onClick={handleSave}>
                            Save
                        </Button>
                    </Box>
                </Paper>
            )}
            <Snackbar
                open={successMessage !== ''}
                autoHideDuration={6000}
                onClose={handleCloseSuccessAlert}
                message={successMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSuccessAlert}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
            <Snackbar
                open={errorMessage !== ''}
                autoHideDuration={6000}
                onClose={handleCloseErrorAlert}
                message={errorMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseErrorAlert}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Please select at least one teacher"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
        </Container>
    )
}

export default ClassTeacherMapping;
