import React, { useState, useEffect } from "react";
import { Container, Table, TableHead, TableBody, TableRow, TableCell, Button,AppBar,Toolbar,Typography,Paper, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

function EnquiryDetails() {
  const [enqDetails, setEnqDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingBarProgress, setLoadingBarProgress] = useState('');
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const fetchEnquiry = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/Enquiry/FetchSchoolEnquiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching financial years: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Sorry! No record found!") {
        throw new Error("Record Not Found");
      }
      setEnqDetails(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    navigate('/addenquiry')
  }

  const handleDelete = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const apiUrl = process.env.REACT_APP_BASE_URL;
      setLoadingBarProgress(30);
      const response = await fetch(`${apiUrl}/Enquiry/Id?EnquiryId=${selectedEnquiryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      if (response.ok) {
        setLoadingBarProgress(100);
        // If deletion is successful, refresh the enquiry data
        fetchEnquiry();
        handleCloseModal();
      } else {
        setLoadingBarProgress(0);
        console.error('Failed to delete enquiry');
        alert('Failed to delete enquiry');
      }
    } catch (error) {
      console.error('API request error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const handleShowModal = (enquiryId) => {
    setSelectedEnquiryId(enquiryId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEnquiryId(null);
  };

  useEffect(() => {
    fetchEnquiry();
  }, []);

  const filteredEnqDetails = enqDetails.filter(enquiry =>
    enquiry.studentName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <AppBar position="static" style={{ backgroundColor: "#0B1F3D" }}>
        <Toolbar>
          <Typography variant="h6" component="div">
            Enquiry Details
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper elevation={3} style={{ padding: 16, width: '100%', margin: 'auto', marginTop: 16 }}>
      <form>
        <div className="form-group" style={{width:'267px', marginTop: "27px"}}>
          <input
            type="text"
            className="form-control"
            id="formGroupExampleInput"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button variant="contained" color="primary" style={{ marginBottom: "10px", marginTop: "20px" }} onClick={handleClick}>Add Enquiry</Button>
        </div>
      </form>
      
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Enquiry No.</TableCell>
            <TableCell>Enquiry Date</TableCell>
            <TableCell>Student Name</TableCell>
            <TableCell>Father name</TableCell>
            <TableCell>Email-Id</TableCell>
            <TableCell>Contact No.</TableCell>
            <TableCell>Class</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredEnqDetails.map((enquiry) => (
            <TableRow key={enquiry.enquiryId}>
              <TableCell>{enquiry.enquiryCode}</TableCell>
              <TableCell>{enquiry.enquiryDate}</TableCell>
              <TableCell>{enquiry.studentName}</TableCell>
              <TableCell>{enquiry.parentAndGurdianName}</TableCell>
              <TableCell>{enquiry.emailId}</TableCell>
              <TableCell>{enquiry.contactNo}</TableCell>
              <TableCell>{enquiry.className}</TableCell>
              <TableCell>
                <Button variant="contained" color="warning" className="my-2"
                  onClick={() => navigate(`/editenquiry/${enquiry.enquiryId}`)} startIcon={<EditIcon />}
                >Edit</Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleShowModal(enquiry.enquiryId)} startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Confirmation Modal */}
      <Modal open={showModal} onClose={handleCloseModal}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, padding: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
          <h2>Delete Confirmation</h2>
          <p>Are you sure you want to delete this enquiry?</p>
          <Button variant="contained" onClick={handleCloseModal} style={{ marginLeft: '1rem' }}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete} style={{ marginLeft: '1rem' }}>
            Yes, Delete
          </Button>
        </div>
      </Modal>
      </Paper>
    </Container>
  );
}

export default EnquiryDetails;
