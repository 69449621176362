import { Container, Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaEye } from 'react-icons/fa';

function EnquiryFollow() {
  const [followUpType, setFollowUpType] = useState("");
  const [showDateFields, setShowDateFields] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [enquiryData, setEnquiryData] = useState([]);
  const [error, setError] = useState(null);
  const [trackedThrough, setTrackedThrough] = useState("");
  const [status, setStatus] = useState("");
  const [loadingBarProgress,setLoadingBarProgress] = useState('');
  const [assignedTo, setAssignedTo] = useState("");
  const [remarks, setRemarks] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetchStatusOptions();
    fetchAssignedToOptions();
  }, []);

  useEffect(() => {
    if (followUpType && followUpType !== "SelectDate") {
      handleSearch();
    }
  }, [followUpType]);

  const handleFollowUpTypeChange = (event) => {
    const selectedType = event.target.value;
    setFollowUpType(selectedType);
    setShowDateFields(selectedType === "SelectDate");
    setEnquiryData([]);
    setError(null);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const fetchStatusOptions = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/Status/GetStatus`;
      const token = sessionStorage.getItem('token');
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching status options: ${response.status}`);
      }
      const data = await response.json();
      setStatusOptions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAssignedToOptions = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/Enquiry/ddlTeacher`;
      const token = sessionStorage.getItem('token');
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching assigned to options: ${response.status}`);
      }
      const data = await response.json();
      setAssignedToOptions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EnquiryFollowUp/FetchFollowUpList`;
      const token = sessionStorage.getItem('token');
      let requestBody = {};

      switch (followUpType) {
        case "SelectDate":
          requestBody = {
            ddlOption: followUpType,
            fromDate: formatDate(fromDate),
            toDate: formatDate(toDate)
          };
          break;
        case "AllCurrentDate":
        case "PendingFollowUp":
          requestBody = {
            ddlOption: followUpType,
            fromDate: new Date().toLocaleDateString('en-GB'),
            toDate: new Date().toLocaleDateString('en-GB')
          };
          break;
        default:
          requestBody = {
            ddlOption: followUpType,
            fromDate: "",
            toDate: ""
          };
          break;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Error fetching follow-up list: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData && responseData.data !== null && responseData.msg !== null) {
        setEnquiryData(responseData);
        setError(null);
      } else {
        setEnquiryData([]);
        setError("No data available");
      }
    } catch (error) {
      console.error(error);
      setError("Error fetching data. Please try again later.");
    }
  };

  const handleTrackedThroughChange = (event) => {
    setTrackedThrough(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleAssignedToChange = (event) => {
    setAssignedTo(event.target.value);
  };

  // const handleRemarksChange = (event) => {
  //   setRemarks(event.target.value);
  // };

  
  const handleCheckboxChange = (event, index) => {
    const newData = [...enquiryData];
    newData[index].isChecked = event.target.checked;
    setEnquiryData(newData);
  };

  const handleDropdownChange = (event, key, index) => {
    const newData = [...enquiryData];
    newData[index][key] = event.target.value;
    setEnquiryData(newData);
  };

  const handleRemarksChange = (event, index) => {
    const newData = [...enquiryData];
    newData[index].remark = event.target.value;
    setEnquiryData(newData);
  };

  const handleNextFollowUpDateChange = (event, index) => {
    const newData = [...enquiryData];
    newData[index].nextFollowUpDate = event.target.value;
    setEnquiryData(newData);
  };

  const handleEyeClick = async (enquiryId) => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EnquiryFollowUp/FollowUpRecordById`;
      setLoadingBarProgress(30);
      const token = sessionStorage.getItem('token');
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ enquiryId }),
      });
      if (!response.ok) {
        //alert(`Error fetching follow-up record: ${response.status}`);
      }
      const data = await response.json();
      setSelectedEnquiry(data[0]); // Assuming only one record is returned
      setModalOpen(true);
    } catch (error) {
      console.error(error);
      // Handle error, maybe display a message to the user
    }
  };

  const validateForm = () => {
    let errors = {};
    enquiryData.forEach((enquiry, index) => {
      if (enquiry.isChecked) { // Validate only checked rows
        if (!enquiry.trackedThrough) {
          errors[`trackedThrough-${index}`] = "Follow Up is required";
        }
        if (!enquiry.status) {
          errors[`status-${index}`] = "Status is required";
        }
        if (!enquiry.assignedTo) {
          errors[`assignedTo-${index}`] = "Assigned To is required";
        }
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };



  const handleSave = async () => {

    if (!validateForm()) {
      return;
    }

    try {
      setLoadingBarProgress(30);
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EnquiryFollowUp/InsertFollowUp`;
      const token = sessionStorage.getItem('token');
  
      // Filter only the checked items
      const checkedEnquiryData = enquiryData.filter(enquiry => enquiry.isChecked);
  
      // Extracting all fields from checkedEnquiryData
      const payload = checkedEnquiryData.map(enquiry => ({
        enquiryDate: enquiry.enquiryDate,
        followUpDate: formatDate(enquiry.nextFollowUpDate),
        enquieryCode: enquiry.enquiryCode,
        parent_GurdianName: enquiry.parent_GaurdianName,
        mobileNo: enquiry.mobile,
        remark: enquiry.remark,
        followUpType: followUpType || '',
        status: enquiry.status,
        enquiryId: enquiry.enquiryId,
        assignId: enquiry.assignId
      }));
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        setLoadingBarProgress(0);
        alert(`Error saving follow-up data: ${response.status}`);
      }
      else{
      setLoadingBarProgress(100);
      // Assuming success, you may want to handle this differently
      alert('Follow-up data saved successfully!');
      handleSearch();
      }
    } catch (error) {
      console.error(error);
      // Handle error, maybe display a message to the user
      alert('Error saving follow-up data. Please try again later.');
    }
  };
  

  return (
    <Container>
      <form>
        <div>
          <input
            className="form-check-input"
            style={{ marginTop: "50px", marginLeft: "10px" }}
          />
        </div>
  
        <h1 style={{ marginTop: "70px" }}>Enquiry-Follow-Up</h1>
  
        <div className="col-md-5 my-3">
          <label htmlFor="inputState" className="form-label">
            Follow Up Type
          </label>
          <select
            id="inputState"
            className="form-select"
            value={followUpType}
            onChange={handleFollowUpTypeChange}
          >
            <option value="">Select Follow Up Type</option>
            <option value="SelectDate">Selected Date Wise</option>
            <option value="AllCurrentDate">Current Date</option>
            <option value="PendingFollowUp">Pending</option>
          </select>
        </div>
  
        {showDateFields && (
          <div className="row my-3">
            <div className="col-md-5">
              <label htmlFor="fromDate" className="form-label">
                From Date
              </label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="col-md-5">
              <label htmlFor="toDate" className="form-label">
                To Date
              </label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-info btn-bg my-2"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        )}
  
        {error && <div className="alert alert-danger">{error}</div>}
  
        {enquiryData.length > 0 && (
         <div className="table-responsive">
         <table className="table table-bordered table-hover">
           <thead>
             <tr>
               <th></th>
               <th>Enquiry Code</th>
               <th>Parent Name</th>
               <th>Mobile</th>
               <th>Enquiry Date</th>
               <th>Enquiry Follow Up Date</th>
               <th style={{ width: "20%" }}>Follow Up</th>
               <th style={{ width: "20%" }}>Status</th>
               <th style={{ width: "20%" }}>Assigned To</th>
               <th style={{ width: "40%" }}>Remarks</th>
               <th></th>
             </tr>
           </thead>
           <tbody>
             {enquiryData.map((enquiry, index) => (
               <tr key={index}>
                 <td>
                   <input
                     type="checkbox"
                     checked={enquiry.isChecked || false}
                     onChange={(event) => handleCheckboxChange(event, index)}
                   />
                 </td>
                 <td>{enquiry.enquiryCode}</td>
                 <td>{enquiry.parent_GaurdianName}</td>
                 <td>{enquiry.mobile}</td>
                 <td>{enquiry.enquiryDate}</td>
                 <td><input
                      type="date"
                      className="form-control"
                      value={enquiry.nextFollowUpDate || ""}
                      onChange={(e) => handleNextFollowUpDateChange(e, index)}
                    /></td>
                 <td>
                   <select
                     value={enquiry.trackedThrough || ""}
                     onChange={(event) => handleDropdownChange(event, 'trackedThrough', index)}
                     className="form-select"
                   >
                     <option value="">--Select--</option>
                     <option value="phone">Phone</option>
                     <option value="email">Email</option>
                   </select>
                   {enquiry.isChecked && formErrors[`trackedThrough-${index}`] && (
                                <p className="text-danger">{formErrors[`trackedThrough-${index}`]}</p>
                              )}
                 </td>
                 <td>
                   <select
                     value={enquiry.status || ""}
                     onChange={(event) => handleDropdownChange(event, 'status', index)}
                     className="form-select"
                   >
                     <option value="">Select Status</option>
                     <option value="Super Hot">Intrested</option>
                     <option value="Very Hot">Very Hot</option>
                     <option value="Hot">Hot</option>
                     <option value="Cold">Cold</option>
                     <option value="Very Cold">Very Cold</option>
                     <option value="Lost">Lost</option>
                   </select>
                   {enquiry.isChecked && formErrors[`status-${index}`] && (
                                <p className="text-danger">{formErrors[`status-${index}`]}</p>
                              )}
                 </td>
                 <td>
                   <select
                     value={enquiry.assignedTo || ""}
                     onChange={(event) => handleDropdownChange(event, 'assignedTo', index)}
                     className="form-select"
                   >
                     <option value="">--Select--</option>
                     {assignedToOptions.map(option => (
                       <option key={option.userId} value={option.employeeName}>{option.employeeName}</option>
                     ))}
                   </select>
                   {enquiry.isChecked && formErrors[`assignedTo-${index}`] && (
                                <p className="text-danger">{formErrors[`assignedTo-${index}`]}</p>
                              )}
                 </td>
                 <td>
                   <input
                     type="text"
                     value={enquiry.remark || ""}
                     onChange={(event) => handleRemarksChange(event, index)}
                     className="form-control"
                   />
                 </td>
                 <td>
                   <FaEye
                     style={{ color: "blue", cursor: "pointer" }}
                     onClick={() => handleEyeClick(enquiry.enquiryId)}
                   />
                 </td>
               </tr>
             ))}
           </tbody>
         </table>
       </div>
       
        )}
  
        <div>
          <button
            type="button"
            className="btn btn-info my-2"
            onClick={handleSave}
          >
            Save
          </button>
          <input
            type="reset"
            className="btn btn-primary"
            value="Reset"
            style={{ marginLeft: "15px" }}
          />
        </div>
      </form>
  
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          width: 400,
          borderRadius: 4,
          textAlign: 'center'
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enquiry Details
          </Typography>
          <TableContainer>
            <Table aria-label="enquiry details">
              <TableBody>
                {selectedEnquiry ? (
                  <>
                    <TableRow>
                      <TableCell>Enquiry Code:</TableCell>
                      <TableCell>{selectedEnquiry.enquiryCode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Parent/Guardian Name:</TableCell>
                      <TableCell>{selectedEnquiry.parent_GaurdianName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Enquiry Follow Up Date:</TableCell>
                      <TableCell>{selectedEnquiry.enquiryFollowUpDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Remarks:</TableCell>
                      <TableCell>{selectedEnquiry.remarks}</TableCell>
                    </TableRow>
                    {/* Add more fields as needed */}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No data found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
  
    </Container>
  
  );  
}

export default EnquiryFollow;
