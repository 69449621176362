import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  Box
} from "@mui/material";
import { Edit, Delete, Visibility, Print, NavigateBefore, NavigateNext, CloudDownload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import IdCard from '@mui/icons-material/CreditCard';
import jsPDF from "jspdf";


const Admission = () => {
  const [admissionData, setAdmissionData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false); // State for modal visibility
  const [selectedFile, setSelectedFile] = useState(null); // State for selected file
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [selectedStudent, setSelectedStudent] = useState(null); // State for selected student to view documents
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [selectedAdmission, setSelectedAdmission] = useState(null); // State for selected admission to delete



  let navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);
  };

  const handleViewDocuments = (student) => {
    setSelectedStudent(student);
    setOpen(true);
  };

  const fetchStudentAdmission = async (studentId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Admission/rptAdmission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: studentId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching admission data: ${response.status}`);
      }

      const data = await response.json();
      // Call function to create receipt or admission form
      generateAdmissionFormPDFInNewTab(data[0]); // Assuming there's only one student data in the response
    } catch (error) {
      console.error(error);
    }
  };




  // Call the function to generate admission form PDF and open it in a new tab
  // generateAdmissionFormPDFInNewTab(studentData);

  const generateAdmissionFormPDFInNewTab = (studentData) => {
    try {
        const doc = new jsPDF();

        // Dynamically fetching school name and logo from sessionStorage
        const schoolName = sessionStorage.getItem("organizationName").replace(/['"]+/g, "") || "IDEAL PUBLIC SCHOOL";
        const logo = sessionStorage.getItem("clientLogo");
        const logoUrl = `https://arizshad-002-site5.ktempurl.com${logo.replace("~", "")}`;

        // Fetch address and contact info from sessionStorage
        const schoolAddress = sessionStorage.getItem("address").replace(/['"]+/g, "") || "STREET NO 1 2 B- BLOCK SARUP VIHAR";
        const contactInfo = sessionStorage.getItem("phoneNo").replace(/['"]+/g, "") || "1234567890";

        // Page 1: Header and Basic Information
        doc.setFont("helvetica", "normal");

        // Add white background to the header
        doc.setFillColor("#FFFFFF");
        doc.rect(0, 0, 210, 35, "F");

        // School Header with dynamic data
        doc.setFontSize(16);
        doc.text(schoolName, 105, 10, { align: "center" });
        doc.setFontSize(12);
        doc.text("(RECOGNIZED)", 105, 16, { align: "center" });
        doc.text(schoolAddress, 105, 22, { align: "center" });

        // Admission Form Title
        doc.setFontSize(14);
        doc.text("ADMISSION FORM", 105, 32, { align: "center" });

        // Dynamic Contact Info
        doc.setFontSize(10);
        doc.text(`Phone No: ${contactInfo}`, 105, 28, { align: "center" });

        // Admission Number and Academic Session
        doc.setFontSize(10);
        doc.text("Admission No: _______________", 20, 45);
        doc.text("Academic Session: 20___ - 20___", 140, 45);

        // Registration Date and Class Info
        doc.text("Reg. on: _______________", 20, 55);
        doc.setFontSize(10).setFont("helvetica", "bold"); // Set bold font for the next line
        doc.text("(WRITE IN CAPITALS ONLY)", 20, 65);
        doc.setFont("helvetica", "normal"); // Reset to normal font
        doc.text("Class to which admission is brought: _______________", 120, 55);

        // Student Info Section with boxes for each input
        const dataFields = [
            { label: "Student's name", value: `${studentData.studentName}` , isBox: true },
            { label: "Aadhaar No", value: `${studentData.adharNo}` , isBox: false, digits: 12 },
            { label: "Mother's name", value: `${studentData.motherName}` , isBox: true },
            { label: "Aadhaar No", value: `${studentData.motherAadharNo}` , isBox: false, digits: 12 },
            { label: "Father's name", value: `${studentData.fatherName}` , isBox: true },
            { label: "Aadhaar No", value: `${studentData.fatherAadharNo}` , isBox: false, digits: 12 },
            { label: "Guardian’s name", value: `${studentData.guardianName} Relation: ${studentData.guardianRelation}`, isBox: true },
            { label: "Date of birth", value: `${studentData.dob}` , isBox: false, format: 'DD/MM/YYYY' },
            { label: "Nationality", value: `${studentData.nationality}` , isBox: true },
            { label: "Category", value: `${studentData.category}` , isBox: true },
            { label: "Caste", value: `${studentData.caste}` , isBox: true },
            { label: "Father's Contact No", value:`${studentData.fatherMobile}` , isBox: false, digits: 10 },
            { label: "Mother's Contact No", value: `${studentData.motherMobile}` , isBox: false, digits: 10 },
            { label: "Blood Group", value: `${studentData.bloodGroup}` , isBox: true },
            { label: "Height", value: `${studentData.height}` , isBox: true },
            { label: "Weight", value: `${studentData.weight}` , isBox: true },
        ];

        // Initialize yPos with a top margin for the Student Info Section
        let yPos = 75; // Starting position for text with a top margin

        dataFields.forEach(({ label, value, isBox, digits, format }) => {
            doc.text(`${label}:`, 20, yPos);

            if (digits) {
                let xPos = 100;
                for (let i = 0; i < digits; i++) {
                    doc.rect(xPos, yPos - 5, 5, 8);
                    xPos += 6;
                }
            } else if (format === 'DD/MM/YYYY') {
                let xPos = 100;
                ['DD', 'MM', 'YYYY'].forEach((part, index) => {
                    if (index > 0) doc.text('/', xPos - 2, yPos);
                    for (let i = 0; i < part.length; i++) {
                        doc.rect(xPos, yPos - 5, 5, 8);
                        xPos += 6;
                    }
                });
            } else if (isBox) {
                doc.rect(100, yPos - 5, 80, 8);
                doc.text(`${value || ''}`, 105, yPos);
            } else {
                doc.text(`${value || '__________________'}`, 100, yPos);
            }

            yPos += 10; // Increase yPos for the next line
        });

        // Information regarding parents/guardian details section
        doc.text("Information regarding parents/guardian details", 20, yPos);
        yPos += 10; // Add space after the title
        const parentInfoFields = [
            { relation: "Mother", education: studentData.motherEducation, occupation: studentData.motherOccupation },
            { relation: "Father", education: studentData.fatherEducation, occupation: studentData.fatherOccupation },
            { relation: "Guardian", education: studentData.guardianEducation, occupation: studentData.guardianOccupation },
        ];

        parentInfoFields.forEach(({ relation, education, occupation }) => {
            doc.text(`${relation}`, 20, yPos);
            doc.text(`Education: ________________`, 60, yPos);
            doc.text(`Occupation: ________________`, 140, yPos);
            yPos += 10; // Increase yPos for the next line
        });

        // Add a new page for the next section
        doc.addPage();

        // Page 2: Last School Details Section (moved above Address)
        doc.setFontSize(12);
        doc.text("Last school attended: ___________________________________________", 20, 20);
        doc.text("Last class passed: ________________", 20, 30);
        doc.text("Medium: ________________", 140, 30);

        // Address Section
        doc.text("Address (permanent/temporary):", 20, 50);
        doc.rect(20, 55, 180, 20);

        // Move yPos for the next text
        let yPosAddress = 85;

        doc.text("Father's Contact No:", 20, yPosAddress);
        for (let i = 0; i < 10; i++) {
            doc.rect(60 + i * 6, yPosAddress - 5, 5, 8);
        }

        // Move to the next line for Mother's Contact No
        yPosAddress += 10;
        doc.text("Mother's Contact No:", 20, yPosAddress);
        for (let i = 0; i < 10; i++) {
            doc.rect(60 + i * 6, yPosAddress - 5, 5, 8);
        }

        // Add a new line for Declaration by Parents/Guardian
        yPosAddress += 20; // Add space before the declaration
        doc.setFontSize(12);
        doc.text("DECLARATION BY PARENTS/GUARDIAN:", 20, yPosAddress);

        // Declaration Text
        const declarationText = [
            "All the information furnished below is correct and verifiable.",
            "I and my child have gone through all prescribed rules and regulations and shall abide by them.",
            "All the fees deposited by us shall not be claimed on any ground.",
            "I/we declare that in case of any mishap or expulsion, no suit or claim will be initiated by me/us.",
            "I/we shall abide by the decision of the principal under all circumstances."
        ];
        let yPosDeclaration = yPosAddress + 10; // Start after declaration title
        declarationText.forEach((line) => {
            doc.text(line, 20, yPosDeclaration);
            yPosDeclaration += 10; // Increase yPos for the next line
        });

        // Signature Section for Parent's Declaration
        doc.text("Father's Sign: __________________", 20, yPosDeclaration + 20);
        doc.text("Mother's Sign: __________________", 80, yPosDeclaration + 20);
        doc.text("Guardian's Sign: __________________", 140, yPosDeclaration + 20);

        // Sibling Information Section
        yPosDeclaration += 50; // Move down for sibling information
        doc.text("Name of the siblings:", 20, yPosDeclaration);
        doc.text("i) __________________________________________________________", 20, yPosDeclaration + 10);
        doc.text("Class: _______________", 140, yPosDeclaration + 10);
        doc.text("ii) _________________________________________________________", 20, yPosDeclaration + 20);
        doc.text("Class: _______________", 140, yPosDeclaration + 20);

        // Add a new page for Documents Section
        doc.addPage();

        // Page 4: Documents Section
        doc.setFontSize(12);
        doc.text("DOCUMENTS RECEIVED:", 20, 20);
        const documents = [
            "DOB certificate / Affidavit",
            "Transfer Certificate (TC)",
            "Electricity bill (within 3 months)",
            "Photocopy of student's Aadhar Card",
            "Photocopy of Mother's Aadhar Card",
            "Photocopy of Father's Aadhar Card",
            "Caste certificate",
            "Income certificate"
        ];
        let yPosDocuments = 30;
        documents.forEach((docLine) => {
            doc.rect(20, yPosDocuments - 5, 5, 5); // Checkbox for each document
            doc.text(docLine, 30, yPosDocuments); // Added margin between checkbox and text
            yPosDocuments += 10; // Increase yPos for the next line
        });

        // Admission Clerk Signature Section
        doc.text("Admission Clerk: __________________", 130, yPosDocuments + 30); // Right margin for Admission Clerk

        // Save the PDF
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");

        // Clean up the URL
        setTimeout(() => {
            URL.revokeObjectURL(pdfUrl);
        }, 5000);
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};




  const fetchStudentId = async (studentId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Admission/rptAdmission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: studentId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching admission data: ${response.status}`);
      }

      const data = await response.json();
      // Call function to create receipt or admission form
      generateIdCardPDFInNewTab(data[0]); // Assuming there's only one student data in the response
    } catch (error) {
      console.error(error);
    }
  };


  const generateIdCardPDFInNewTab = (studentData) => {
    try {
        // ID card size
        const idCardWidth = 100; // Width of the card
        const idCardHeight = 120; // Height of the card

        // Create a new jsPDF instance with custom paper size
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [idCardWidth, idCardHeight]
        });

        // Add the school name with red background like the provided card
        const schoolName = sessionStorage.getItem('organizationName') || 'IDEAL PUBLIC SCHOOL';
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(14); // Font size for the school name

        // Red background for the school name
        const schoolNameBgHeight = 20; // Height of the red background
        const schoolNameBgYPos = 0; // Remove top margin by setting Y position closer to the top
        doc.setFillColor(200, 0, 0); // Red color for the background
        doc.rect(0, schoolNameBgYPos, idCardWidth, schoolNameBgHeight, 'F'); // Fill the rectangle with red

        // Add the logo beside the school name
        const logo = sessionStorage.getItem('clientLogo');
        const logoUrl = `https://arizshad-002-site5.ktempurl.com${logo.replace('~', '')}`;
        let logoWidth = 10; // Adjusted width for smaller logo
        const logoHeight = 10; // Adjusted height for smaller logo
        let logoXPos = 5; // X position to place the logo beside the school name
        const logoYPos = schoolNameBgYPos + 1; // Align the logo with the school name, no top margin
        
        // Adjust the width of the school name based on the available space beside the logo
        let availableWidth = idCardWidth - logoWidth - 10; // 10mm padding for spacing

        // Set white color for school name text and position it within the red background
        doc.setTextColor(255, 255, 255);
        const schoolNameWidth = doc.getTextWidth(schoolName);
        let xPosSchoolName = logoXPos + logoWidth + 2; // Positioned beside the logo
        if (schoolNameWidth > availableWidth) {
            doc.setFontSize(12); // Decrease font size if the name is too long
            xPosSchoolName = logoXPos + logoWidth + 2; // Recalculate position with reduced font
        }
        const yPosSchoolName = schoolNameBgYPos + 7; // Slightly lower than background
        doc.text(schoolName, xPosSchoolName, yPosSchoolName);

        if (logoUrl) {
            doc.addImage(logoUrl, 'JPEG', logoXPos, logoYPos, logoWidth, logoHeight);
        }

        // Add session text below the school name
        const sessionText = 'Session 2024-2025';
        doc.setFontSize(10);
        doc.setTextColor(0, 100, 0); // Dark green color for session text
        const sessionTextWidth = doc.getTextWidth(sessionText);
        const xPosSessionText = (idCardWidth - sessionTextWidth) / 2;
        const yPosSessionText = yPosSchoolName + 6;
        doc.text(sessionText, xPosSessionText, yPosSessionText);

        // Add profile image inside the red-bordered frame with reduced top margin
        const profileImgYPos = 34; // Reduced top margin
        const profileImgSize = 25;
        const profileImgXPos = (idCardWidth - profileImgSize) / 2;
        const profileImgUrl = `https://arizshad-002-site5.ktempurl.com/${studentData.profileImage.replace('~', '')}`;
        if (profileImgUrl) {
            doc.addImage(profileImgUrl, 'JPEG', profileImgXPos, profileImgYPos, profileImgSize, profileImgSize);
        }

        // Add student name in bold red below the profile image
        doc.setFont('helvetica', 'bold');
        const studentName = studentData.studentName || 'KHUSHI';
        doc.setFontSize(12);
        const studentNameWidth = doc.getTextWidth(studentName);
        const studentNameXPos = (idCardWidth - studentNameWidth) / 2;
        const studentNameYPos = profileImgYPos + profileImgSize + 4; // Adjusted spacing
        doc.setTextColor(200, 0, 0); // Red for student name
        doc.text(studentName, studentNameXPos, studentNameYPos);

        // Add a thin divider line below the student name
        doc.setLineWidth(0.2); // Thinner line
        const lineStartX = 10;
        const lineEndX = idCardWidth - 10;
        const lineYPos = studentNameYPos + 2; // Position just below the student name
        doc.line(lineStartX, lineYPos, lineEndX, lineYPos);

        // Add student details (Father, Mother, Class, etc.)
        const studentDetailsYPos = studentNameYPos + 8;
        const details = [
            ["Father's Name:", studentData.guardianName || 'MR. SANDEEP KUMAR UPADHYAY'],
            ["Mother's Name:", studentData.motherName || 'N/A'],
            ["Class:", studentData.class || 'Nur'],
            ["Admission No:", studentData.admissionNo || 'Imp0019'],
            ["Address:", studentData.address || 'N/A'],
            ["Phone:", studentData.phone || 'N/A']
        ];

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0); // Black for details
        const lineHeight = 5;
        details.forEach(([label, value], index) => {
            doc.text(`${label} ${value}`, 5, studentDetailsYPos + lineHeight * index);
        });

        // Add footer address in bold white text with red background
        const footerBgYPos = idCardHeight - 15;
        doc.setFillColor(200, 0, 0); // Red background
        doc.rect(0, footerBgYPos, idCardWidth, 15, 'F');

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(7);
        doc.setTextColor(255, 255, 255); // White color for footer text

        // New footer text with school address
        const footerText1 = 'SCHOOL ID: 1152593, C-44/151-152,';
        const footerText2 = 'STREET NO.-10, SUDAMAPURI, GAMRI EXTN';
        const footerText1Width = doc.getTextWidth(footerText1);
        const footerText2Width = doc.getTextWidth(footerText2);
        const footerText1XPos = (idCardWidth - footerText1Width) / 2;
        const footerText2XPos = (idCardWidth - footerText2Width) / 2;

        doc.text(footerText1, footerText1XPos, footerBgYPos + 5);
        doc.text(footerText2, footerText2XPos, footerBgYPos + 10);

        // Save the PDF
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');

        setTimeout(() => {
            URL.revokeObjectURL(pdfUrl);
        }, 5000);
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};



  const handleDelete = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      // const apiUrl = 'https://arizshad-002-site5.atempurl.com/api';
      const token = sessionStorage.getItem('token');

      const response = await fetch(`${apiUrl}/Admission/Id?Id=${selectedAdmission.studentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Error deleting admission: ${response.status}`);
      }

      // Handle success, e.g., refresh the admission data
      fetchAdmission();
      setShowDeleteModal(false);
    } catch (error) {
      console.error(error);
    }
  };







  const handleUpload = async () => {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem('token');
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(`${apiUrl}/Admission/importAdmission`, {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error uploading file: ${response.status}`);
      }
      alert('Data imported successfully')

      // Handle success response
      handleClose();

      // You may want to refetch the admission data here
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    navigate("/addadmission");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const fetchAdmission = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Admission/GetAdmission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error(`Error fetching admission data: ${response.status}`);
      }

      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
  
      setAdmissionData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdmission();
  }, []);

  const handleDownload = async () => {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem('token');
  
    try {
      const response = await fetch(`${apiUrl}/Admission/DownloadExcel_AdmissionReport`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
  
      if (!response.ok) {
        throw new Error(`Error downloading file: ${response.status}`);
      }
  
      // Get current date and time
      const now = new Date();
      const formattedDate = now.toISOString().split('T')[0]; // YYYY-MM-DD
      const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-'); // HH-MM-SS
      const filename = `AdmissionReport_${formattedDate}_${formattedTime}.csv`;
  
      // Convert response to blob
      const blob = await response.blob();
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Create an anchor element to initiate download
      const a = document.createElement('a');
      a.href = url;
      a.download = filename; // Set the filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // Revoke the temporary URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };
  
  

  const displayedData = admissionData
  .filter((year) => {
    const studentNameMatch = year.studentName.toLowerCase().includes(searchTerm.toLowerCase());
    const classNameMatch = year.className.toLowerCase().includes(searchTerm.toLowerCase());
    return studentNameMatch || classNameMatch;
  });


    //commented the below line to fetch all the data in single page
    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Function to handle opening of image or PDF in new tab
  const handleViewDocument = (path) => {
    
    window.open(`https://arizshad-002-site5.ktempurl.com/${path}`, "_blank");
  };


  const handleDownloadSample = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/Admission/downloadFormatImportAdmission`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'Sample_Import_Admission.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  
  return (
    <Container sx={{ marginTop: 5 }}>
      <AppBar position="static" style={{ backgroundColor: "#0B1F3D" }}>
        <Toolbar>
          <Typography variant="h4" component="div">
            Admission Report
          </Typography>
        </Toolbar>
      </AppBar>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        placeholder="Search by student name or class"
        value={searchTerm}
        onChange={handleSearch}
      />

      <Button
        variant="outlined"
        sx={{
          mt: 2,
          mx: 1,
          backgroundColor: "#2196f3",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#2196f3", // Set the same color as the background to remove hover effect
          },
        }}
        onClick={handleClick}
      >
        NEW ADMISSION
      </Button>
      <Button
        variant="outlined"
        sx={{
          mt: 2,
          mx: 1,
          backgroundColor: "#f50057",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#f50057",
          },
        }}
        onClick={handleOpenModal} // Open the modal
      >
        IMPORT
      </Button>

      <Button
        variant="outlined"
        sx={{
          mt: 2,
          mx: 1,
          backgroundColor: "#3f51b5",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#3f51b5",
          },
        }}
        onClick={handleDownload} // Call the handleDownload function
      >
        <CloudDownload sx={{ mx: 1 }} />
        DOWNLOAD
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3, minWidth: 950 }}>
        <Table sx={{ minWidth: 650 }} aria-label="admission table">
          <TableHead sx={{ color: "#ffffff" }}>
            <TableRow>
              <TableCell>Admission No</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Mobile No</TableCell>
              <TableCell>Documents</TableCell>
              <TableCell>Admission Form</TableCell>
              <TableCell>Identity Card</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((year) => (
              <TableRow key={year.admissionNo}>
                <TableCell>{year.admissionNo}</TableCell>
                <TableCell>{year.studentName}</TableCell>
                <TableCell>{year.className}</TableCell>
                <TableCell>{year.mobileNo}</TableCell>
                <TableCell>
                  <Button onClick={() => handleViewDocuments(year)}>View</Button>
                </TableCell>
                <TableCell>
                  <IconButton size="small">
                    <Print onClick={() => fetchStudentAdmission(year.studentId)} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => fetchStudentId(year.studentId)}>
                    <IdCard /> {/* New IconButton for Identity Card */}
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton size="small" color="warning" onClick={() => navigate(`/updateadmission/${year.studentId}`)}>
                    <Edit />
                  </IconButton>
                  <IconButton size="small" color="error" onClick={() => {
                    setSelectedAdmission(year);
                    setShowDeleteModal(true);
                  }}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px" }}>
        <IconButton
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
          sx={{ color: page === 0 ? "grey" : "#2196f3" }}
        >
          <NavigateBefore />
        </IconButton>
        <span style={{ margin: "0 8px", fontSize: "16px" }}>{page + 1}</span>
        <IconButton
          onClick={() => handleChangePage(page + 1)}
          disabled={page + 1 >= Math.ceil(admissionData.length / rowsPerPage)}
          sx={{ color: page + 1 >= Math.ceil(admissionData.length / rowsPerPage) ? "grey" : "#2196f3" }}
        >
          <NavigateNext />
        </IconButton>
      </div>

      {/* Modal for viewing documents */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="documents-modal-title"
        aria-describedby="documents-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          maxWidth: "80%", // Adjust the width of the modal
          maxHeight: "80%", // Adjust the height of the modal
          overflow: "auto", // Enable scrolling if content overflows
        }}>
          <h2 id="documents-modal-title">View Documents</h2>
          {selectedStudent && (
            <div>
              <Button
                onClick={() => handleViewDocument(selectedStudent.aadharPathFront)}
                disabled={!selectedStudent.aadharPathFront}
                sx={{ mr: 1 }}
              >
                Aadhar Front
              </Button>
              <Button
                onClick={() => handleViewDocument(selectedStudent.adharPathBack)}
                disabled={!selectedStudent.adharPathBack}
                sx={{ mr: 1 }}
              >
                Aadhar Back
              </Button>
              <Button
                onClick={() => handleViewDocument(selectedStudent.birthDocumentPath)}
                disabled={!selectedStudent.birthDocumentPath}
                sx={{ mr: 1 }}
              >
                Birth Certificate
              </Button>
              <Button
                onClick={() => handleViewDocument(selectedStudent.transferDocumentPath)}
                disabled={!selectedStudent.transferDocumentPath}
                sx={{ mr: 1 }}
              >
                Transfer Certificate
              </Button>
              <Button
                onClick={() => handleViewDocument(selectedStudent.otherDocumentPath)}
                disabled={!selectedStudent.otherDocumentPath}
              >
                Others
              </Button>
            </div>
          )}
        </div>
      </Modal>


 

       {/* Modal for file upload */}
       <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '60%',
              bgcolor: 'background.paper',
              borderRadius: 1,
              boxShadow: 24,
              p: 4,
            }}
          >
            <AppBar position="static" style={{ backgroundColor: "#0B1F3D", marginBottom: "20px" }}>
              <Toolbar>
                <Typography variant="h4" component="div">
                  Upload Excel File
                </Typography>
              </Toolbar>
            </AppBar>
            <Typography id="upload-modal-title" variant="h6" component="div" gutterBottom>
              Tips for Uploading File
            </Typography>
            <ul style={{ marginBottom: "20px", paddingLeft: "20px", listStyleType: "disc" }}>
              <li>Ensure the file is in .xlsx format.</li>
              <li>Make sure all required fields are filled out.</li>
              <li>Check for any duplicate entries.</li>
              <li>Remove any special characters from the data.</li>
              <li>Ensure proper column headers are used as per the template.</li>
            </ul>
            <input
              type="file"
              // accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ marginBottom: "20px", display: "block" }}
            />
            <Box display="flex" justifyContent="space-between">
              <Button variant="contained" onClick={handleUpload} color="primary">
                Upload
              </Button>
              <Button variant="contained" onClick={handleDownloadSample} color="warning">
                Download Sample Report
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>


      {/* Delete Confirmation Modal */}
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            maxWidth: "400px",
          }}
        >
          <h2 id="delete-modal-title">Delete Confirmation</h2>
          <p id="delete-modal-description">
            Are you sure you want to delete this admission ?
          </p>
          <Button variant="contained" className="mx-2" color="error" onClick={handleDelete}>
            Yes, Delete
          </Button>
          <Button variant="contained" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>


    </Container>
  );
};

export default Admission;