import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, TextField, Table, TableBody,AppBar,Toolbar,Typography, TableCell, TableContainer, TableHead, TableRow, Paper,InputAdornment } from '@mui/material';
import LoadingBar from 'react-top-loading-bar';
import SearchIcon from '@mui/icons-material/Search';
import base64 from 'base64-js';
import Tooltip from '@mui/material/Tooltip';


const Organization = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState({ organizationId: null, organizationName: '' });
  const [loadingBarProgress, setLoadingBarProgress] = useState(0);
  const [authorization, setAuthorization] = useState([]);
  const [canDelete, setCanDelete] = useState(true); // Default to true, assuming user can delete
  const [canEdit, setCanEdit] = useState(true); // Default to true, assuming user can edit
  const { encodedFormId } = useParams();

  const decodeFormId = (encodedFormId) => {
    const bytes = base64.toByteArray(encodedFormId);
    return new TextDecoder().decode(bytes);
  };
  const formId = decodeFormId(encodedFormId);
  console.log(formId);


  const handleDelete = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      setLoadingBarProgress(30);
      const response = await fetch(`${apiUrl}/Organization/OrgId/OrganizationId=${selectedOrganization.organizationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      if (response.ok) {
        setLoadingBarProgress(100);
        fetchOrganizationData();
        setShowModal(false);
      } else {
        setLoadingBarProgress(0);
        console.error('Delete failed');
        alert('Failed to delete organization');
      }
    } catch (error) {
      console.error('API request error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const handleAddOrganization = () => {
    navigate('/addOrganization');
    console.log('Navigate to Add Organization page');
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShow = (organizationId, organizationName) => {
    setSelectedOrganization({ organizationId, organizationName });
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const fetchOrganizationData = async () => {
    try {
      // const apiUrl = process.env.REACT_APP_BASE_URL;
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/Organization/GetOrganization`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          // connectionString: sessionStorage.getItem('ConnectionString'),
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
      } else {
        console.error('Account name incorrect');
        alert('Invalid account name');
      }
    } catch (error) {
      console.error('API request error:', error);
      alert('An error occurred. Please try again later.');
    }
  };
  useEffect(() => {
    fetchOrganizationData();
    Authorizer();
  }, []);


  const Authorizer = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/CPanel/Module_Authorizer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          formId,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setAuthorization(responseData);
        // Check permissions for Delete and Edit
        const authorizationData = responseData[0];
        setCanDelete(authorizationData.uDelete === 1);
        setCanEdit(authorizationData.uModify === 1);
      } else {
        console.error('Country name incorrect');
        alert('Invalid country name');
      }
    } catch (error) {
      console.error('API request error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  useEffect(() => {
    fetchOrganizationData();
    Authorizer();
  }, []);

  return (
    <div className="container mt-4">
      <LoadingBar progress={loadingBarProgress} color="rgb(152, 106, 182)" height={5} />
      <AppBar position="static" style={{ backgroundColor: "#0B1F3D" }}>
        <Toolbar>
          <Typography variant="h6" component="div">
           Organization
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper elevation={3} style={{ padding: 16, width: '100%', margin: 'auto', marginTop: 16 }}>
      <div className="container mt-4">
        {/* Search input */}
        <div className="container mt-2" style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
  <TextField
    variant="outlined"
    placeholder="Search..."
    value={searchTerm}
    onChange={(e) => handleInputChange(e.target.value)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      style: { padding: '0px 8px' },
    }}
    sx={{
      width: 300,
      backgroundColor: '#f9f9f9',
    }}
  />
</div>


        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>Organization Name</b></TableCell>
                <TableCell><b>Email-Id</b></TableCell>
                <TableCell><b>Mobile No.</b></TableCell>
                <TableCell><b>Action</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.schoolId}>
                  <TableCell>{row.schoolName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobileNo}</TableCell>
                  <TableCell>
                    <Tooltip title={canEdit ? '' : 'You are not authorized to edit'} arrow>
                      <span>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => navigate(`/editOrganization/${row.schoolId}`)}
                          disabled={!canEdit}
                        >
                          Edit
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip title={canDelete ? '' : 'You are not authorized to delete'} arrow>
                      <span>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleShow(row.schoolId, row.schoolName)}
                          disabled={!canDelete}
                        >
                          Delete
                        </Button>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Delete Confirmation Modal */}
        <Modal open={showModal} onClose={handleClose} centered>
          <div>
            <h2>Delete Confirmation</h2>
            <p>Are you sure you want to delete {selectedOrganization.organizationName}?</p>
            
            <Button variant="contained" color="error" onClick={handleDelete}>
              Yes, Delete
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
      </Paper>
    </div>
  );
};

export default Organization;
