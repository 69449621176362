import React, { useState } from 'react';
import ProfileModal from './StudentProfileModal';
import CourseBatchModal from './CourseBatchModal';
import PaymentDetailsModal from './PaymentDetailsModal';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './module.css';
import AttendanceCalender from './AttendanceCalender';
import { useNavigate } from 'react-router-dom';

function StdDashboard() {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCourseBatchModal, setShowCourseBatchModal] = useState(false);
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);

  let navigate = useNavigate();

  const handleOpenProfileModal = () => {
    setShowProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
  };

  const handleOpenCourseBatchModal = () => {
    setShowCourseBatchModal(true);
  };

  const handleCloseCourseBatchModal = () => {
    setShowCourseBatchModal(false);
  };

  const handleOpenPaymentDetailsModal = () => {
    setShowPaymentDetailsModal(true);
  };

  const handleClosePaymentDetailsModal = () => {
    setShowPaymentDetailsModal(false);
  };

  const handleAssignmentClick = () => {
    navigate('/assignmentlist');
  };

  return (
    <>
    <div className="my-task">
      <h2>
        <i className="fas fa-tachometer-alt"></i> Dashboard
      </h2>
      <div className="task-cards">
        <div className="task-card purple" onClick={handleOpenProfileModal}>
          <h3>Profile</h3>
          <p>88 tasks</p>
          <p>75% completed</p>
          {showProfileModal && <ProfileModal onClose={handleCloseProfileModal} />}
        </div>
        <div className="task-card orange" onClick={handleOpenCourseBatchModal}>
          <h3>Courses</h3>
          <p>38 tasks</p>
          <p>30% completed</p>
          {showCourseBatchModal && <CourseBatchModal onClose={handleCloseCourseBatchModal} />}
        </div>
        <div className="task-card green" onClick={handleOpenPaymentDetailsModal}>
          <h3>Fee Details</h3>
          <p>56 tasks</p>
          <p>55% completed</p>
          {showPaymentDetailsModal && <PaymentDetailsModal onClose={handleClosePaymentDetailsModal} />}
        </div>
        {/* The remaining cards can open any modal or be placeholders for future functionality */}
        <div className="task-card pink" onClick={handleAssignmentClick}>
          <h3>Assignment</h3>
          <p>42 tasks</p>
          <p>50% completed</p>
        </div>
        <div className="task-card blue">
          <h3>Quiz</h3>
          <p>22 tasks</p>
          <p>90% completed</p>
        </div>
      </div>
    </div>
    <div className="bottom-section">
        <AttendanceCalender/>
    </div>
    </>
  );
}

export default StdDashboard;
