import React from 'react';
import { TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Paper, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function TopicPage() {
    const[topicData, setTopicData] = useState([]);
    let navigate = useNavigate();



    const FetchTopic = async () => {
        try {
          const apiUrl = process.env.REACT_APP_BASE_URL;
          const token = sessionStorage.getItem("token");
          const response = await fetch(`${apiUrl}/OfflineTopicMaster/GetTopicMaster`, {
            method: "POST",
            headers: {
              "content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({}),
          });
    
          if (!response.ok) {
            throw new Error(`Error fetching topic data: ${response.status}`);
          }
      
          const data = await response.json();
      
          if (data.data === null && data.msg === "Record Not Found") {
            console.error('Record Not Found');
            alert('Record Not Found');
            return; // Exit the function if the record is not found
          }
      
          setTopicData(data);
        } catch (error) {
          console.error("API request error:", error);
          alert("An error occurred. Please try again later.");
        }
      };
    
      useEffect(() => {
        FetchTopic();
      },[]);

    const handleEditLesson = (topicId) => {
      navigate(`/edittopicmaster/${topicId}`)
    };

    const handleAdd = () =>{
        navigate('/topicmaster');
    }

    const handleDeleteLesson = (lessonId) => {
        // Handle delete lesson logic
    };

    return (
        <div>

            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Topic Details
                </Typography>
                <div style={{ marginBottom: '20px' }}>
                    <TextField
                        label="Search Lesson"
                        variant="outlined"
                    // Add search functionality here
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px', marginTop: '5px' }}
                        onClick={handleAdd}
                    // Add functionality to add a new lesson
                    >
                        Add Lesson
                    </Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Topic</TableCell>
                            <TableCell>Subject Name</TableCell>
                            <TableCell>Lesson Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topicData.map((topic) => (
                            <TableRow key={topic.topicId}>
                                <TableCell>{topic.className}</TableCell>
                                <TableCell>{topic.topic}</TableCell>
                                <TableCell>{topic.subjectName}</TableCell>
                                <TableCell>{topic.lessionName}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        onClick={() => handleEditLesson(topic.topicId)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        style={{ marginLeft: '5px' }}
                                        onClick={() => handleDeleteLesson(topic.topicId)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}

export default TopicPage;