import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const GetTimeTab = () => {
  const [classes, setClasses] = useState([]);
  const [classId, setClassId] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [sections, setSections] = useState([]);
  const [tableData, setTableData] = useState([]); // For storing timetable data
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Store the row to be deleted

  const handleAddTime = () => {
    // Add time logic here
  };

  const handleEdit = (row) => {
    console.log("Edit", row);
    // Add edit logic here
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row); // Set the row to be deleted
    setOpenDeleteDialog(true); // Open the confirmation dialog
  };

  const handleDeleteConfirm = async () => {
    // Call the delete API here
    const apiUrl = process.env.REACT_APP_BASE_URL;
    const deleteUrl = `${apiUrl}/TimeTable/Id?Id=${selectedRow.timeTableId}`; // Use the selected row ID
    try {
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });

      if (response.ok) {
        // Remove the deleted row from the tableData
        setTableData((prevTableData) => prevTableData.filter((row) => row.id !== selectedRow.id));
        alert('Time table entry deleted successfully');
      } else {
        alert('Failed to delete time table entry');
      }
    } catch (error) {
      console.error('Error deleting timetable:', error);
      alert('An error occurred while deleting the timetable');
    } finally {
      setOpenDeleteDialog(false); // Close the dialog
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false); // Close the dialog without deleting
  };

  const handleGetTimeTable = async () => {
    if (!classId || !sectionId) {
      alert("Please select both Class and Section");
      return;
    }
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/TimeTable/GetTimeTable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          classId,
          sectionId,
        }),
      });
      const data = await response.json();
      setTableData(data); // Store the fetched timetable data in state
    } catch (error) {
      console.error('Error fetching timetable:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchClasses = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      if (data.data === null && data.msg === 'Record Not Found') {
        throw new Error('Record Not Found');
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.atempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
          classId,
        }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  useEffect(() => {
    fetchClasses(); // Fetch classes on component mount
  }, []);

  const handleClassChange = (event) => {
    const selectedClassId = event.target.value;
    setClassId(selectedClassId);
    setSectionId(''); // Reset section when class changes
    fetchSections(selectedClassId); // Fetch sections based on selected class ID
  };

  const handleSectionChange = (event) => {
    setSectionId(event.target.value); // Set selected section ID
  };

  return (
    <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Paper style={{ padding: 16, maxWidth: 700, width: '100%', marginBottom: 20, background: '#f5f5f5' }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: '#3f51b5', fontWeight: 'bold' }}
            >
              Time Table
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Class"
              value={classId}
              onChange={handleClassChange}
              fullWidth
            >
              {classes.length > 0 ? (
                classes.map((cls) => (
                  <MenuItem key={cls.classId} value={cls.classId}>
                    {cls.className}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Classes Available</MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Section"
              value={sectionId}
              onChange={handleSectionChange}
              fullWidth
              disabled={sections.length === 0}
            >
              {sections.length > 0 ? (
                sections.map((section) => (
                  <MenuItem key={section.sectionId} value={section.sectionId}>
                    {section.sectionName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Sections Available</MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#3f51b5',
                color: '#ffffff',
                marginRight: 8,
                fontWeight: 'bold',
              }}
              onClick={handleAddTime}
            >
              Add Time
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#4caf50',
                color: '#ffffff',
                fontWeight: 'bold',
              }}
              onClick={handleGetTimeTable}
              disabled={loading} // Disable button during loading
            >
              {loading ? 'Loading...' : 'Get Time Table'}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper} style={{ maxWidth: 800, width: '100%', borderRadius: 8 }}>
        <Table size="small" style={{ minWidth: 500 }}>
          <TableHead style={{ backgroundColor: '#3f51b5' }}>
            <TableRow>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Day of Week</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Start Time</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>End Time</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Class Name</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Section Name</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Subject Name</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Teacher Name</TableCell>
              <TableCell style={{ color: '#ffffff', fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ? (
              tableData.map((row, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                  hover
                >
                  <TableCell>{row.dayOfWeek}</TableCell>
                  <TableCell>{row.startTime}</TableCell>
                  <TableCell>{row.endTime}</TableCell>
                  <TableCell>{row.className}</TableCell>
                  <TableCell>{row.sectionName}</TableCell>
                  <TableCell>{row.subjectName}</TableCell>
                  <TableCell>{row.teacherName}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(row)}
                      style={{ color: '#4caf50' }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteClick(row)}
                      style={{ color: '#f44336' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Time Table Entry</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this time table entry? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GetTimeTab;
