import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, TextField, Button, TextareaAutosize } from '@mui/material';

const EditOrganization = () => {
  let navigate = useNavigate();
  const { organisationId } = useParams();
  const [formData, setFormData] = useState({
    organisationName: '',
    email: '',
    mobileNo: '',
    landlineNo: '',
    address: '',
    zipCode: '',
    fax: '',
    website: '',
  });
  const[loadingBarProgress,setLoadingBarProgress] = useState('');

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        setLoadingBarProgress(30);
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${apiUrl}/Organization/ConnectionString`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            connectionString: sessionStorage.getItem('ConnectionString'),
          }),
        });

        if (response.ok) {
          const responseData = await response.json();
          setLoadingBarProgress(30);
          const selectedOrganization = responseData.find(
            (org) => org.organisationId === parseInt(organisationId, 10)
          );

          if (selectedOrganization) {
            setFormData(selectedOrganization);
          } else {
            console.error('Organization not found for the given organisationId');
          }
        } else {
          setLoadingBarProgress(0);
          console.error('Failed to fetch organization data');
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    };

    fetchOrganizationData();
  }, [organisationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      setLoadingBarProgress(30);
      const response = await fetch(`${apiUrl}/Organization/OrgId?OrganizationId=${organisationId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          OrganisationName: formData.organisationName,
          email: formData.email,
          MobileNo: formData.mobileNo,
          LandlineNo: formData.landlineNo,
          address: formData.address,
          zipCode: formData.zipCode,
          fax: formData.fax,
          website: formData.website,
        }),
      });

      if (response.ok) {
        setLoadingBarProgress(100);
        alert('Details Updated Successfully');
        setFormData('');
        navigate('/Organization');
      } else {
        setLoadingBarProgress(0);
        alert('Error Updating Details');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleCancel = () => {
    console.log('Edit cancelled');
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Edit Organization
      </Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Organization Name"
              variant="outlined"
              name="organisationName"
              value={formData.organisationName}
              placeholder="Enter organization name"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              type="email"
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              placeholder="Enter email"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="tel"
              label="Mobile No."
              variant="outlined"
              name="mobileNo"
              value={formData.mobileNo}
              placeholder="Enter mobile number"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              type="tel"
              label="Landline No."
              variant="outlined"
              name="landlineNo"
              value={formData.landlineNo}
              placeholder="Enter landline number"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextareaAutosize
              fullWidth
              rowsMin={3}
              placeholder="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Zip Code"
              variant="outlined"
              name="zipCode"
              value={formData.zipCode}
              placeholder="Enter zip code"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Fax"
              variant="outlined"
              name="fax"
              value={formData.fax}
              placeholder="Enter fax number"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Website"
              variant="outlined"
              name="website"
              value={formData.website}
              placeholder="Enter website URL"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: '16px' }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleUpdate}
          >
            Update
          </Button>
          <Button
            type="button"
            variant="contained"
            color="error"
            size="small"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default EditOrganization;
