import { Container, Typography, Paper, Select, FormControl, MenuItem, Button, InputLabel, Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";

function ClassMapping() {
  const [ddlClass, setddlClass] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState('');
  const [subjectMapping, setSubjectMapping] = useState([]);

  const fetchddlClass = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching class names: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      
      setddlClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClassChange = async (event) => {
    setSelectedClassId(event.target.value);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ClassMapping/GetSubjectMapping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ classId: event.target.value }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching subject mapping: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setSubjectMapping(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const payload = subjectMapping
        .filter(subject => subject.checked)
        .map(subject => ({
          classId: selectedClassId,
          subjectId: subject.subjectId
        }));
      const response = await fetch(apiUrl + '/ClassMapping', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`Error saving data: ${response.status}`);
      }
      setSubjectMapping([]);
      setSelectedClassId('');
      alert('Data saved successfully');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchddlClass();
  }, []);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '50vh',
        marginTop: '30px'
      }}
    >
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Class Mapping
        </Typography>
        <form style={{ width: '300px' }}>
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel htmlFor="class">Class:</InputLabel>
            <Select
              id="class"
              value={selectedClassId}
              onChange={handleClassChange}
              label="Class"
            >
              <MenuItem value="">Select Class</MenuItem>
              {ddlClass.map((item) => (
                <MenuItem key={item.classId} value={item.classId}>
                  {item.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </Paper>
      
      {subjectMapping.length > 0 && (
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No</TableCell>
                <TableCell>Subject Name</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subjectMapping.map((subject, index) => (
                <TableRow key={subject.subjectId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{subject.subjectName}</TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={subject.status === "1"}
                      checked={subject.checked || false}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setSubjectMapping(prevState => prevState.map(item =>
                          item.subjectId === subject.subjectId ? { ...item, checked } : item
                        ));
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Button onClick={handleSave} variant="contained" color="primary" sx={{ mx: 2 }}>
              Save
            </Button>
            </div>
        </Paper>
      )}
    </Container>
  );
}

export default ClassMapping;
