import React, { useEffect, useState } from 'react';
import './module.css';

function StudentProfileModal() {
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState(null);

  const fetchStudentData = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/Student/FetchStudentProfile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "studentID": sessionStorage.getItem('employeeId')
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching student profile: ${response.status}`);
      }

      const data = await response.json();
      if ( data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
        return;
      }

      setStudentData(data[0]);
      
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const handleCloseProfileModal = () => {
    window.location.reload();
  };

  useEffect(() => {
    fetchStudentData();
  }, []);

  if (error) {
    return <div className="modals-overlay">Error: {error}</div>;
  }

  if (!studentData) {
    return <div className="modals-overlay">Loading...</div>;
  }

  return (

    <div className="modal-overlay" 
    style={{
      position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex', justifyContent: 'center', alignItems: 'center'
    }}>
      <div className="modal-content"
      style={{
        backgroundColor: 'rgb(8, 16, 102)', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '600px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
      }}>
        <div className="modal-header"
         style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #181239', paddingBottom: '10px', marginBottom: '20px'
        }}>
          <h2 style={{margin: '0'}}>Profile</h2>

          <button className="close-button" onClick={handleCloseProfileModal}>X</button>
        </div>

        <div className="modals-body">
          <p><strong>Admission No:</strong> {studentData.admissionNo}</p>
          <p><strong>Full Name:</strong> {studentData.studentName}</p>
          <p><strong>Email-Id:</strong> {studentData.eamilId}</p>
          <p><strong>Mobile-No:</strong> {studentData.mobileNo}</p>
          <p><strong>DOB:</strong> {studentData.studentDOB}</p>
          <p><strong>Admission Date:</strong> {studentData.admissionDate}</p>
        </div>
      </div>
    </div>
  );
}
export default StudentProfileModal;
