import React, { useEffect, useState } from 'react';
import { Modal, Typography, Button, AppBar, Toolbar, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AttendanceCalender = ({ isOpen, handleClose, studentId }) => {
    const [studentProfile, setStudentProfile] = useState(null);
    const [monthsData, setMonthsData] = useState([]);

    useEffect(() => {
        const fetchStudentProfile = async () => {
            try {
                const apiUrl = process.env.REACT_APP_BASE_URL;
                const token = sessionStorage.getItem('token');
                const response = await fetch(`${apiUrl}/Attendance/StudentProfile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token,
                    },
                    body: JSON.stringify({
                        "studentId": 5
                    }),
                });
                if (response.ok) {
                    const data = await response.json();
                    if (data.status === null && data.msg === "Record Not Found") {
                        return; // Exit the function if the record is not found
                      }
                    setStudentProfile(data.objData); // Set the entire objData object received from the API response
                    setMonthsData(data.objRec);
                } else {
                    console.error('Failed to fetch student profile');
                }
            } catch (error) {
                console.error('API request error:', error);
            }
        };
        fetchStudentProfile();

    }, []);

    const renderProfileDetails = () => {
        if (!studentProfile) return null;

    };

    return (

        <div className='my-progress'>
            <AppBar position="static" style={{ backgroundColor: "#0B1F3D", marginTop: '10px', textAlign: 'center' }}>
                <Toolbar>
                    <Typography variant="h4" component="div">
                        Attendance Record
                    </Typography>
                </Toolbar>
            </AppBar>
            <TableContainer style={{ marginTop: '20px', maxWidth: '1400px', margin: 'auto',width:'1200px'}}>
                <Table style={{ borderCollapse: 'collapse', border: '1px solid #ddd' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Month</TableCell>
                            {[...Array(31).keys()].map(day => (
                                <TableCell key={day + 1} style={{ border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>{day + 1}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {monthsData.map((month, index) => (
                            <TableRow key={month.month}>
                                <TableCell style={{ border: '1px solid #ddd', backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>{month.month}</TableCell>
                                {[...Array(31).keys()].map(day => (
                                    <TableCell key={day + 1} style={{ border: '1px solid #ddd', backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>{month[Object.keys(month)[day + 1]]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
};

export default AttendanceCalender;
