import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

const AssignmentUpdate = () => {
  const [teachersList, setTeacherList] = useState([]);
  const [classData, setClassData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');

  const fetchTeacher = async () => {
    try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${apiUrl}/AssignmentCreate/GetTeacher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            throw new Error(`Error fetching class: ${response.status}`);
          }
          const data = await response.json();
          if (data.data === null && data.msg === "Record Not Found") {
            return; // Exit the function if the record is not found
          }
          setTeacherList(data);
        } catch (error) {
          console.error(error);
        }
      };
  const fetchClasses = async (teacherId) => {
    try {
      // Fetch class data
      const url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${url}/AssignmentCreate/GetClass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching classes: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return; // Exit the function if the record is not found
      }

      setClassData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSections = async (teacherId, classId) => {
    try {
      // Fetch section data
      const url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${url}/AssignmentCreate/ddlSection_clsId`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId, classId }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching sections: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return; // Exit the function if the record is not found
      }
      setSectionData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubjects = async (classId, sectionId, teacherId) => {
    try {
      // Fetch subject data
      const url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${url}/AssignmentCreate/Subjectddl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ classId, sectionId, teacherId }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching subjects: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return; // Exit the function if the record is not found
      }
      setSubjectData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTeacherChange = (event) => {
    const selectedTeacherId = event.target.value;
    setSelectedTeacher(selectedTeacherId);
    fetchClasses(selectedTeacherId);
  };

  const handleClassChange = (event) => {
    const selectedClassId = event.target.value;
    setSelectedClass(selectedClassId);
    fetchSections(selectedTeacher, selectedClassId);
  };

  const handleSectionChange = (event) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    fetchSubjects(selectedClass, selectedSectionId, selectedTeacher);
  };

  useEffect(() => {
    fetchTeacher();
  }, []);

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Assignment
        </Typography>
        <form style={{ marginTop: '10px' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="teacher">Teacher</InputLabel>
                <Select
                  id="teacher"
                  value={selectedTeacher}
                  onChange={handleTeacherChange}
                  label="Teacher"
                >
                  {teachersList.map((item) => (
                    <MenuItem key={item.employeeId} value={item.employeeId}>{item.employeeName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="class">Class</InputLabel>
                <Select
                  id="class"
                  value={selectedClass}
                  onChange={handleClassChange}
                  label="Class"
                >
                  {classData.map((classItem) => (
                    <MenuItem key={classItem.classId} value={classItem.classId}>{classItem.className}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="section">Section</InputLabel>
                <Select
                  id="section"
                  value={selectedSection}
                  onChange={handleSectionChange}
                  label="Section"
                >
                  {sectionData.map((sectionItem) => (
                    <MenuItem key={sectionItem.sectionId} value={sectionItem.sectionId}>{sectionItem.sectionName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="subject">Subject</InputLabel>
                <Select
                  id="subject"
                  value={selectedSubject}
                  onChange={(event) => setSelectedSubject(event.target.value)}
                  label="Subject"
                >
                  {subjectData.map((subjectItem) => (
                    <MenuItem key={subjectItem.subjectId} value={subjectItem.subjectId}>{subjectItem.subjectName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField id="assignmentDate" label="Assignment Date" variant="outlined" fullWidth style={{ marginBottom: '20px' }} />
              <TextField id="submissionDate" label="Submission Date" variant="outlined" fullWidth style={{ marginBottom: '20px' }} />
              <TextField
                id="assignmentDescription"
                label="Assignment Description"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                style={{ marginBottom: '20px' }}
              />
              <TextField id="uploadAssignment" type="file" InputLabelProps={{ shrink: true }} fullWidth />
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button variant="contained" color="success" size="large">
              SAVE
            </Button>
            <Button variant="contained" color="primary" size="large" style={{ marginLeft: '10px' }}>
              CANCEL
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default AssignmentUpdate;
