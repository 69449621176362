import React, { useState, useEffect } from "react";
import { Container, Paper, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ExamSubCategory() {
  const [className, setClassName] = useState([]);
  const [examTypes, setExamTypes] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedClassTypeId, setSelectedClassTypeId] = useState("");
  const [selectedExamCategoryId, setSelectedExamCategoryId] = useState("");
  const [formData, setFormData] = useState({
    subExam: "",
    minMarks: 0,
    maxMarks: 0,
    weightage: 0
  });
  let navigate = useNavigate();

  const fetchClassddl = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ClassType/GetClassType`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.data !== null) {
          setClassName(responseData);
        } else {
          console.error('No data found for classes');
        }

        if (responseData.msg && responseData.msg !== 'Record Not Found') {
          console.error('API error:', responseData.msg);
        }
      } else {
        console.error('Failed to fetch class data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const fetchExamTypes = async (classTypeId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ExaminationSubCategory/ExamTypeddl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          classTypeId
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.data !== null) {
          setExamTypes(responseData);
        } else {
          console.error('No data found for exam types');
        }

        if (responseData.msg && responseData.msg !== 'Record Not Found') {
          console.error('API error:', responseData.msg);
        }
      } else {
        console.error('Failed to fetch exam types data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleClassTypeChange = (event) => {
    const classTypeId = event.target.value;
    setSelectedClassTypeId(classTypeId);
    fetchExamTypes(classTypeId);
  };
  const handleExamTypeChange = (event) => {
    const examTypeId = event.target.value;
    setSelectedExamCategoryId(examTypeId);
  };


  const handleSave = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ExaminationSubCategory`;
      const token = sessionStorage.getItem('token');
      
      const requestData = {
        examCategoryId: selectedExamCategoryId || 2,
        classTypeId: selectedClassTypeId,
        subExam: formData.subExam,
        minMarks: formData.minMarks,
        maxMarks: formData.maxMarks,
        weightage: formData.weightage
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        alert('Data saved successfully');
        setFormData({
          subExam: "",
          minMarks: 0,
          maxMarks: 0,
          weightage: 0
        });
        fetchExamSubCategory(); // Refresh the table data after saving
      } else {
        console.error('Failed to save data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleCancel = () => {
    setFormData({
      subExam: "",
      minMarks: 0,
      maxMarks: 0,
      weightage: 0
    });
  };

  const handleEdit = (item) => {
    // Handle edit action
  };

  const handleDelete = (item) => {
    // Handle delete action
  };


  const fetchExamSubCategory = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ExaminationSubCategory/GetExamSubCategory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.data !== null) {
          setSubCategory(responseData);
        } else {
          console.error('No data found for classes');
        }

        if (responseData.msg && responseData.msg !== 'Record Not Found') {
          console.error('API error:', responseData.msg);
        }
      } else {
        console.error('Failed to fetch class data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };


  useEffect(() => {
    fetchClassddl();
    fetchExamSubCategory();
  }, []);

  return (
    <Container maxWidth="lg" style={{ marginTop: "40px" }}>
      <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h5" style={{ marginBottom: "20px" }}>Exam SubCategory</Typography>

        <FormControl fullWidth style={{ marginBottom: "20px" }}>
          <InputLabel htmlFor="classType">Class Type</InputLabel>
          <Select
            id="classType"
            label="Class Type"
            value={selectedClassTypeId}
            onChange={handleClassTypeChange}
          >
            <MenuItem value=""><em>--Select--</em></MenuItem>
            {className.map((item) => (
              <MenuItem key={item.classTypeId} value={item.classTypeId}>
                {item.classType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: "20px" }}>
          <InputLabel htmlFor="examType">Exam Type</InputLabel>
          <Select
            id="examType"
            label="Exam Type"
            defaultValue=""
            value={selectedExamCategoryId}
            onChange={handleExamTypeChange}
          >
            <MenuItem value=""><em>--Select--</em></MenuItem>
            {examTypes.map((item) => (
              <MenuItem key={item.examCategoryId} value={item.examCategoryId}>
                {item.examType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Sub Exam"
          value={formData.subExam}
          onChange={(e) => setFormData({ ...formData, subExam: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <TextField
          fullWidth
          label="Min Marks"
          type="number"
          value={formData.minMarks}
          onChange={(e) => setFormData({ ...formData, minMarks: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <TextField
          fullWidth
          label="Max Marks"
          type="number"
          value={formData.maxMarks}
          onChange={(e) => setFormData({ ...formData, maxMarks: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <TextField
          fullWidth
          label="Weightage"
          type="number"
          value={formData.weightage}
          onChange={(e) => setFormData({ ...formData, weightage: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="success" style={{ marginRight: "6px" }} onClick={handleSave}>
            <b>Save</b>
          </Button>
          <Button variant="contained" color="warning" onClick={handleCancel}>
            <b>Cancel</b>
          </Button>
        </div>

        <Typography variant="h5" style={{ marginTop: "40px" }}>Exam SubCategory Table</Typography>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sub Exam</TableCell>
                <TableCell>Class Type</TableCell>
                <TableCell>Exam Type</TableCell>
                <TableCell>Min Marks</TableCell>
                <TableCell>Max Marks</TableCell>
                <TableCell>Weightage</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subCategory.map((item) => (
                <TableRow key={item.subCategoryId}>
                  <TableCell>{item.subExam}</TableCell>
                  <TableCell>{item.classType}</TableCell>
                  <TableCell>{item.examType}</TableCell>
                  <TableCell>{item.minMarks}</TableCell>
                  <TableCell>{item.maxMarks}</TableCell>
                  <TableCell>{item.weightage}</TableCell>
                  <TableCell>
                    <Button className="mx-2" variant="contained" color="primary" onClick={() => navigate(`/updateexamsubcategory/${item.subCategoryId}`)}>Edit</Button>
                    <Button variant="contained" color="secondary" onClick={() => handleDelete(item)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}

export default ExamSubCategory;
