import React, { useState, useEffect } from 'react';
import { Container, Grid, TextField, MenuItem, Button, Paper, Typography } from '@mui/material';

const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

const CreateTimeTab = () => {
    const [classes, setClasses] = useState([]);
    const [classRoom, setClassRoom] = useState('0');
    const [section, setSection] = useState('0');
    const [sections, setSections] = useState([]);
    const [teachersList, setTeacherList] = useState([]);
    const [selectedTeacherId, setSelectedTeacherId] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [formData, setFormData] = useState({
        dayOfWeek: '',
        startTime: '',
        endTime: '',
        classId: '',
        sectionId: '',
        subjectId: '',
        teacherId: '',
        roomNo: '',
    });

    const fetchClasses = async () => {
        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify({}),
            });
            const data = await response.json();
            if (data.data === null && data.msg === "Record Not Found") {
                throw new Error("Record Not Found");
            }
            setClasses(data);
        } catch (error) {
            console.error('Error fetching classes:', error);
        }
    };

    const fetchSections = async (classId) => {
        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${apiUrl}/Teacher/ddlSection_clsId`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify({
                    teacherId: sessionStorage.getItem('employeeId'),
                    classId,
                }),
            });
            const data = await response.json();
            setSections(data);
        } catch (error) {
            console.error('Error fetching sections:', error);
        }
    };

    const fetchTeachers = async () => {
        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${apiUrl}/AssignmentCreate/GetTeacher`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                throw new Error(`Error fetching class: ${response.status}`);
            }
            const data = await response.json();
            if (data.data === null && data.msg === "Record Not Found") {
                return; // Exit the function if the record is not found
            }
            setTeacherList(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSubjects = async (classId, sectionId, teacherId) => {
        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${apiUrl}/AssignmentCreate/Subjectddl`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify({
                    classId,
                    sectionId,
                    teacherId,
                }),
            });
            const data = await response.json();
            setSubjects(data);
        } catch (error) {
            console.error('Error fetching subjects:', error);
        }
    };

    useEffect(() => {
        fetchClasses();
        fetchTeachers();
    }, []);

    useEffect(() => {
        if (classRoom !== '0' && section !== '0' && selectedTeacherId !== '') {
            fetchSubjects(classRoom, section, selectedTeacherId);
        }
    }, [classRoom, section, selectedTeacherId]);

    const handleClassChange = (event) => {
        const selectedClassId = event.target.value;
        setClassRoom(selectedClassId);
        setSection('0'); // Reset section and subjects when class changes
        setSubjects([]);
        fetchSections(selectedClassId); // Fetch sections based on selected class ID
    };

    const handleSectionChange = (event) => {
        setSection(event.target.value);
    };

    const handleTeacherChange = (event) => {
        setSelectedTeacherId(event.target.value);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCancel = () => {
        setFormData({
            dayOfWeek: '',
            startTime: '',
            endTime: '',
            classId: '',
            sectionId: '',
            subjectId: '',
            teacherId: '',
            roomNo: '',
        });
        setClassRoom('0');
        setSection('0');
        setSelectedTeacherId('');
        setSubjects([]);
    };

    const handleSubmit = async () => {
        const timetableEntry = {
            classId: parseInt(classRoom, 10),
            sectionId: parseInt(section, 10),
            subjectId: parseInt(formData.subjectId, 10),
            teacherId: parseInt(selectedTeacherId, 10),
            roomNo: parseInt(formData.roomNo, 10),
            dayOfWeek: formData.dayOfWeek,
            startTime: formData.startTime,
            endTime: formData.endTime,
        };

        try {
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${apiUrl}/TimeTable/CreateTime`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify([timetableEntry]), // Send data as an array
            });

            if (!response.ok) {
                throw new Error(`Error submitting data: ${response.status}`);
            }

            const result = await response.json();
            console.log('TimeTable created successfully:', result);

            // Reset the form after successful submission
            handleCancel();

        } catch (error) {
            console.error('Error submitting timetable:', error);
        }
    };

    return (
        <Container maxWidth="md">
            <Paper
                sx={{
                    p: 4,
                    mt: 5,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
                    Create TimeTable Form
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Days of Week"
                            name="dayOfWeek"
                            value={formData.dayOfWeek}
                            onChange={handleChange}
                            variant="outlined"
                        >
                            {daysOfWeek.map((day) => (
                                <MenuItem key={day} value={day}>
                                    {day}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Start Time"
                            type="time"
                            name="startTime"
                            value={formData.startTime}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="End Time"
                            type="time"
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            name="class"
                            value={classRoom}
                            label="Class"
                            onChange={handleClassChange}
                        >
                            <MenuItem value="0"><em>Select</em></MenuItem>
                            {classes.map((classItem) => (
                                <MenuItem key={classItem.classId} value={classItem.classId}>
                                    {classItem.className}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            name="section"
                            value={section}
                            label="Section"
                            onChange={handleSectionChange}
                            variant="outlined"
                        >
                            <MenuItem value="0"><em>Select</em></MenuItem>
                            {sections.map((sectionItem) => (
                                <MenuItem key={sectionItem.sectionId} value={sectionItem.sectionId}>
                                    {sectionItem.sectionName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Teacher"
                            name="teacherId"
                            value={selectedTeacherId}
                            onChange={handleTeacherChange}
                            variant="outlined"
                        >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            {teachersList.map((item) => (
                                <MenuItem key={item.employeeId} value={item.employeeId}>
                                    {item.employeeName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Subject"
                            name="subjectId"
                            value={formData.subjectId}
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            {subjects.map((subject) => (
                                <MenuItem key={subject.subjectId} value={subject.subjectId}>
                                    {subject.subjectName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Room No"
                            name="roomNo"
                            value={formData.roomNo}
                            onChange={handleChange}
                            type="number"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2, borderRadius: 2, backgroundColor: '#3f51b5' }}
                            onClick={handleSubmit} // Trigger handleSubmit on click
                        >
                            Add
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCancel}
                            sx={{ borderRadius: 2, backgroundColor: '#f50057' }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default CreateTimeTab;
