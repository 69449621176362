import React from 'react';
import { useEffect, useState } from 'react';
import './module.css';
function PaymentDetailsModal({ onClose }) {

    const [paymentData, setPaymentData] = useState(null);
    const [error, setError] = useState(null);
  
    const fetchSubject = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${apiUrl}/Student/FetchStudentClassDetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            "studentID": sessionStorage.getItem('employeeId')
          }),
        });
        if (!response.ok) {
          throw new Error(`Error fetching student profile: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.data === null && data.msg === "Record Not Found") {
          throw new Error("Record Not Found");
        }
  
        setPaymentData(data);
      } catch (error) {
        setError(error.message);
        console.error(error);
      }
    };
  
    const handleCloseModal = () => {
      window.location.reload();
    };
  
    useEffect(() => {
      fetchSubject();
    }, []);
  
    if (error) {
      return <div className="modal-overlay">Error: {error}</div>;
    }
  
    if (!paymentData) {
      return <div className="modal-overlay">Loading...</div>;
    }


  return (
    <div className="modal-overlay" 
    style={{
      position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex', justifyContent: 'center', alignItems: 'center'
    }}>
      <div className="modal-content"
      style={{
        backgroundColor: 'rgb(8, 16, 102)', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '600px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
      }}
      >
        <div className="modal-header"
        style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #181239', paddingBottom: '10px', marginBottom: '20px'
        }}
        >
          <h2 style={{margin: '0'}}>Payment Details</h2>
          <button className="close-button" onClick={handleCloseModal}>X</button>
        </div>
        <div className="modal-body">
          <p>No record to show</p>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetailsModal;
