import React, { useState, useEffect } from 'react';
import { FaTachometerAlt, FaBook, FaTasks, FaVideo, FaCog, FaBars, FaSignOutAlt, FaBell, FaUserCircle, FaCalendarAlt } from 'react-icons/fa';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './module.css';
import { Link, useNavigate } from 'react-router-dom';

function StudentsSidebar({ content }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  let navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const studentName = sessionStorage.getItem('clientName');
  const OrgName = sessionStorage.getItem('organizationName').replace(/['"]+/g, '');

  return (
    <div className="app" style={{ display: 'flex' }}>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <h2>{OrgName}</h2>
        <ul>
          <li><Link to='/studentDashboard' style={{ color: '#90949e' }}><FaTachometerAlt className="icon" /> Dashboard</Link></li>
          <li><Link to='/xyz' style={{ color: '#90949e' }}><FaBook className="icon" /> My class</Link></li>
          <li><Link to='/assignmentList' style={{ color: '#90949e' }}><FaTasks className="icon" /> Assignment</Link></li>
          <li><Link to='/xyz' style={{ color: '#90949e' }}><FaVideo className="icon" /> Live Online Classes</Link></li>
          <li><Link to='/xyz' style={{ color: '#90949e' }}><FaCog className="icon" /> Setting</Link></li>
          <li><button onClick={handleLogout} style={{ color: '#90949e', background: 'none', border: 'none', cursor: 'pointer' }}><FaSignOutAlt className="icon" /> Logout</button></li>
        </ul>
      </div>
      <button className="hamburger" onClick={toggleSidebar}>
        <FaBars />
      </button>
      <div className="main-content">
        <div className="header">
          <div className="greeting">
            <h1>Welcome {studentName.replace(/['"]+/g, '')}!</h1>
            <img
              src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdzNjcnIyc2Q2aXpjMHNtdzBmdm0xMWpuOWlnYmJjYXY2c29tZTUzYiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/Qvpb6dqUQ1Hufrbi3t/giphy.gif"
              alt="Hi"
              className="hi-gif"
            />
          </div>
          <div className="header-icons">
            <FaBell className="icon" />
            <FaUserCircle className="icon" />
            <div className="calendar-container">
              <FaCalendarAlt className="icon" onClick={handleCalendarToggle} />
              {showCalendar && <Calendar className="calendar-popup" />}
              <div className="date-time">
                {currentDateTime.toLocaleDateString()} {currentDateTime.toLocaleTimeString()}
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          {content}
        </div>
      </div>
    </div>
  );
}

export default StudentsSidebar;
